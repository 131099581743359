<template>
  <div>
    <van-cell-group style="margin-top:1px">
      <van-cell style="padding:15px 10px 16px 10px">
        <template #title>
          <span class="custom-title hidden-text">
            <svg-icon icon-class="layers" size="18" style="margin-right:8px" /> {{form.schshiName}}
          </span>
        </template>
        <template #right-icon>
          <div class="status-div">
            <span class="status-div-text">{{form.status === '1' ?"已开启":"未开启"}}</span>
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <van-form ref="form">
      <van-field v-model="form.schshiNo" disabled label="项目代码" />
      <van-field v-model="typeName" disabled label="项目类型" />
      <van-field v-model="form.isCustom" disabled label="模板类型">
        <template #input>
          <span style="color:#c8c9cc" v-if="form.isCustom">自定义</span>
          <span style="color:#c8c9cc" v-else>标准</span>
        </template>
      </van-field>
      <custom-date-picker ref="startTime" v-model="form.startTime" label="开始时间" placeholder="点击选择开始时间" />
      <custom-date-picker ref="endTime" v-model="form.endTime" label="结束时间" placeholder="点击选择结束时间" />
      <custom-radio v-model="form.status" label="项目状态" :columns="dict.jz_xm_status" />
      <van-field v-model="form.remarks" forms="3" class="textarea-style" label="备注" type="textarea" />
    </van-form>
    <div class="submit-save shadow-style">
      <van-button round block type="primary" class="submit-save-subbtn" :loading="loading" @click="doSubmit">提 交</van-button>
    </div>
  </div>

</template>

<script>
import { isDuringDate, getTermYear, getTerm } from "@/utils/index";
import { mapGetters } from "vuex";
import { getAwardById } from '@/api/modules/jz/project'
import { validCustom } from "@/utils";
import { Dialog } from "vant";
import crudJzProject from '@/api/modules/jz/project'


export default {
  name: "ProjectDetail",
  components: {},
  dicts: ["jz_xm_status", 'jz_xm_type'],
  data() {
    return {
      form: {},
      typeName: "",
      loading: false,
      customRuleFileds: ['startTime', 'endTime']
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {
    let id = this.$route.query.code
    this.$nextTick((e) => {
      getAwardById(id).then((data) => {
        if (data) {
          this.form = data
          let type = data.typeNo === "jxj" ? "奖学金" : "助学金";
          this.typeName = type;
        }
      })
    });
  },
  methods: {
    isDuringDate,
    doSubmit() {
      this.$refs.form.validate().then(
        (res) => {
          if (validCustom(this.customRuleFileds, this)) {
            // 验证通过数据提交
            Dialog.confirm({
              title: "提示",
              message: "你确定要进行提交操作吗？",
            })
              .then(() => {
                this.toSave(this.form);
              })
              .catch(() => {
                // on cancel
              });
          } else {
            this.$notify({
              type: "warning",
              message: "有提交项未填写！",
              duration: 1500,
            });
          }
        },
        (err) => {
          validCustom(this.customRuleFileds, this);
          this.$notify({
            type: "warning",
            message: "有提交项未填写！",
            duration: 1500,
          });
        }
      );
    },
    toSave(data) {
      this.loading = true
      crudJzProject.edit(data).then((res) => {
        this.loading = false
        this.form = {}
        this.typeName = ""
        this.$notify({
          type: "success",
          message: "操作成功",
          duration: 2000,
        });
        this.$router.go(-1);
      }).catch((err) => {
        this.$notify({
          type: "warning",
          message: "无访问权限",
          duration: 1500,
        });
      })
    }
  },
};
</script>

<style  lang='scss' scoped>
.textarea-style {
  height: 100% !important;
}
.status-div {
  width: 60px;
  height: 22px;
  background-color: rgba(35, 171, 114, 0.16);
  border-radius: 30px;
  text-align: center;
  &-text {
    color: rgba(35, 171, 114, 1);
  }
}

.van-field__label {
  width: 110px;
}
.van-cell {
  font-size: 15px;
  line-height: 32px;
  height: 50px;
}
.van-cell__left-icon,
.van-cell__right-icon {
  line-height: 32px;
}

::v-deep.submit-save {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 6px 0;
  line-height: var(--footer-height);
  text-align: center;
  &-subbtn {
    width: 90%;
    margin-left: 5%;
  }
}
</style>
